import React, { ReactNode } from "react";
import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";
import { Layout, Responsive, WidthProvider } from "react-grid-layout";
import Avatar from "react-avatar";

import { INITIAL_LAYOUTS } from "./layouts";
import { useAuth } from "../../context/auth";

import DateCard from "./components/DateCard";
import MailCard from "./components/MailCard";
import NotificationCard from "./components/NotificationCard";
import AccountCard from "./components/AccountCard";
import Widget from "./components/Widget";
import { ReactComponent as OffIcon } from "../../assets/svg/off.svg";
import { ReactComponent as GearIcon } from "../../assets/svg/gear.svg";
import { ReactComponent as ProjectIcon } from "../../assets/svg/pm.svg";
import { ReactComponent as SupIcon } from "../../assets/svg/sup.svg";
import { ReactComponent as FIcon } from "../../assets/svg/acc.svg";
import { ReactComponent as RLIcon } from "../../assets/svg/acc.svg";
import { ReactComponent as RHIcon } from "../../assets/svg/rh.svg";
import { ReactComponent as WEIcon } from "../../assets/svg/wh.svg";
import { ReactComponent as SMIcon } from "../../assets/svg/sales.svg";

import { ReactComponent as IVIcon } from "../../assets/svg/invest.svg";
import { ReactComponent as EDIcon } from "../../assets/svg/doc.svg";
import { ReactComponent as STIcon } from "../../assets/svg/sub.svg";
import { ReactComponent as PKIcon } from "../../assets/svg/truck1.svg";

import styles from "./styles.module.scss";

interface IWidget {
  title: string;
  subTitle: string;
  url: string;
  key: string;
  backgroundColor?: string;
  icon: ReactNode;
  level: string;
}

const deprt: IWidget[] = [
  {
    title: "R.L",
    subTitle: "Resources Library",
    url: process.env.REACT_APP_URL + "/library/#/",
    key: "resources",
    backgroundColor: "#9c27b0",
    icon: <RLIcon height={80} width={80} />,
    level: "1",
  },
  {
    title: "P.M",
    subTitle: "Project Management",
    backgroundColor: "#673AB7",
    icon: <ProjectIcon height={80} width={80} />,
    url: process.env.REACT_APP_URL + "/pm/#/",
    key: "project",
    level: "11",
  },
  {
    subTitle: "S & L",
    title: "Supply & Logistics",
    backgroundColor: "#03a9f4",
    icon: <SupIcon height={80} width={80} />,
    url: process.env.REACT_APP_URL + "/dal/#/",
    key: "supply",
    level: "2,3,4",
  },

  {
    title: "F & A",
    subTitle: "Finance & Accounting",
    icon: <FIcon height={80} width={80} />,
    url: process.env.REACT_APP_URL + "/dfc/#/",
    key: "finance",
    level: "6",
  },
  {
    title: "Warehouse ",
    subTitle: "Warehouse ",
    icon: <SMIcon height={80} width={80} />,
    url: process.env.REACT_APP_URL + "/stock/#/",
    key: "wh",
    level: "13",
  },
  {
    title: "P.I",
    subTitle: "Prommotion Immobiliére",
    backgroundColor: "#711a62",
    icon: <WEIcon height={80} width={80} />,
    url: process.env.REACT_APP_URL + "/sale/#/",
    key: "immo",
    level: "7",
  },
  /*  {
    title: "Business management",
    subTitle: "Commercial",
    icon: <WEIcon height={80} width={80} />,
    url: process.env.REACT_APP_URL + "/sale/#/",
    key: "sm",
    level: "14",
  }, */
  {
    title: "H.R",
    subTitle: "Human Resource",
    backgroundColor: "#17a2b8",
    icon: <RHIcon height={80} width={80} />,
    url: process.env.REACT_APP_URL + "/rh/#/",
    key: "rh",
    level: "5",
  },
  {
    title: "E.D",
    subTitle: "E-Doc Management",
    backgroundColor: "#f5a74f",
    icon: <EDIcon height={80} width={80} />,
    url: process.env.REACT_APP_URL + "/dag/#/",
    key: "dag",
    level: "10",
  },
  {
    title: "I & V",
    subTitle: "Investissement & Wealth",
    backgroundColor: "#729135",
    icon: <IVIcon height={80} width={80} />,
    url: process.env.REACT_APP_URL + "/invest/#/",
    key: "invest",
    level: "15",
  },
  {
    title: "C & T",
    subTitle: "Car & Truck",
    backgroundColor: "#8f7782",
    icon: <PKIcon height={80} width={80} />,
    url: process.env.REACT_APP_URL + "/park/#/",
    key: "park",
    level: "16",
  },
  {
    title: "S.T",
    subTitle: "Sub - Contractor",
    backgroundColor: "#706a62",
    icon: <STIcon height={80} width={80} />,
    url: process.env.REACT_APP_URL + "/st/#/",
    key: "st",
    level: "7",
  },
];

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function AppLauncher() {
  const {  user,logout } = useAuth();
  const [layouts, setLayouts] = React.useState(INITIAL_LAYOUTS);
  const [isDragging, setIsDragging] = React.useState(false);
 // const LEVELS = authTokens.level.split(","); //[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const _handleLayoutChange = (layout: Layout[], la: any) => {
    // saveToLS("layouts", la);
    // setLayouts(la);
    //console.log(layout);
  };

 

  const _switchMode = () => {};

  const _handleDragStart = () => {
    setIsDragging(true);
  };

  const _handleDragStop = () => {
    setIsDragging(false);
  };

  function checkAutorized(authorized_levels: any, user_levels: any) {
    for (let i = 0; i < authorized_levels.length; i++) {
      const authorized_lvl = authorized_levels[i].toString();
      if (user_levels.includes(authorized_lvl)) {
        return true;
      }
    }

    return false;
  }

  return (
    <div className={styles.appLauncherContainer}>
      <div className={styles.headerWrapper}>
        <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
          <Avatar name={user.nom} size="40" />
          <div>
            <h4 className={styles.avatarTitle}>{user.nom}</h4>
            <h6 className={styles.avatarSubTitle}>{user.departement}</h6>
          </div>
        </div>
        <div style={{ flex: 1, display: "flex", alignItems: "left" }}>
          <img
            alt="Logo"
            style={{ width: "8%" }}
            width={320}
            src={process.env.REACT_APP_LOGO}
          />
          <div>
            <h1 className="company">
              <b>{process.env.REACT_APP_OWNER_NAME}</b>
            </h1>
          </div>
        </div>
        <div className={styles.btn} onClick={_switchMode}>
          <GearIcon width={20} height={20} />
        </div>
        <div className={styles.btn} onClick={logout}>
          <OffIcon width={20} height={20} />
        </div>
      </div>

      <ResponsiveGridLayout
        className={styles.layout}
        breakpoints={{
          //xxl: 1600,
          xl: 1400,
          lg: 1200,
          md: 996,
          sm: 768,
          xs: 480,
          xxs: 300,
        }}
        cols={{ xl: 12, lg: 12, md: 12, sm: 12, xs: 2, xxs: 1 }}
        layouts={layouts}
        rowHeight={40}
        isDraggable={false}
        isResizable={false}
        onLayoutChange={_handleLayoutChange}
        onDragStop={_handleDragStop}
        onDragStart={_handleDragStart}
        onBreakpointChange={(newBreakpoint: string, newCols: number) => {
          console.log("newBreakpoint : ", `(${newBreakpoint})`);
          console.log("newCols : ", `(${newCols})`);
        }}
      >
        {deprt.map((dep) => {
          let depLevel = dep.level.split(",");

          let disabled = false;
         /*  if (depLevel.length === 1) {
            disabled = !LEVELS.includes(dep.level);
          } else {
            disabled = !checkAutorized(depLevel, LEVELS);
          } */
          return (
            <div className={styles.boxWrapper} key={dep.key}>
              <Widget
                disabled={disabled}
                //disabled={true}
                title={dep.title}
                subTitle={dep.subTitle}
                icon={dep.icon}
                backgroundColor={dep.backgroundColor}
                isDragging={false}
                url={dep.url}
              />
            </div>
          );
        })}

        <div className={styles.boxWrapper} key="message">
          <MailCard isDragging={isDragging} />
        </div>
        <div className={styles.boxWrapper} key="date">
          <DateCard isDragging={isDragging} />
        </div>
        <div className={styles.boxWrapper} key="notifications">
          <NotificationCard isDragging={isDragging} />
        </div>
        <div className={styles.boxWrapper} key="profile">
          <AccountCard isDragging={isDragging} />
        </div>
      </ResponsiveGridLayout>
      <div className={styles.footer}>
        {/* <div>
          <img width="150" src="img/ITGB_PNG.png" alt="Logo GBit" />
          <p>
            Copyright © 2014-2024{" "}
            <a target="blanc" href="https://it-gb.com/">
              SARL ITGB
            </a>
          </p>
        </div> */}
      </div>
    </div>
  );
}
