import React, { useEffect, useState } from "react";
import { HashRouter as Router, Link, Redirect, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

import {AuthProvider } from "./context/auth";
import Login from "./pages/Login";
import Applauncher from "./pages/AppLauncher";

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

function App(props) {
  
  return (
    <AuthProvider>
      <Router>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route path="/login" component={Login} />
        <PrivateRoute path="/home" component={Applauncher} />
      </Router>
    </AuthProvider>
  );
}

export default App;
