import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./context/auth";
import "./Loader.css"; // Import the CSS file for the loader

function PrivateRoute({ component: Component, ...rest }) {
  const { isAuthenticated, isLoading } = useAuth();
  if (isLoading) return <div className="loader"></div>;
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

export default PrivateRoute;
