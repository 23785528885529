import { useState } from "react";
import { Redirect } from "react-router-dom";
import { useAuth } from "../context/auth";
import styles from "./styles.module.scss";
import CryptoJS from 'crypto-js';

const ENCRYPTION_KEY = '5f2b5cdbe5194f10b3241568fe4e2b24';

export const encryptData = (data) => {
  // Generate a random IV (16 bytes)
  const iv = CryptoJS.lib.WordArray.random(16);

  // Encrypt the data with AES-256-CBC
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY), {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
  });

  // Combine IV and ciphertext, then encode in base64
  return CryptoJS.enc.Base64.stringify(iv.concat(encrypted.ciphertext));
};

export const decryptData = (encryptedData) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};


function Login(props) {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(null);
  const [isTimer, setIsTimer] = useState(null);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const { login,isAuthenticated } = useAuth();


  const referer = props.location?.state?.referer || "/";

  function toFormData(data) {
    const tmp = new FormData();
    Object.keys(data).forEach((key) => {
      tmp.append(key, data[key]);
    });
    return tmp;
  }

  const postLogin = async (e) => {
    e.preventDefault();
    // Données à crypter
    const credentials = {
      username,
      password,
      timestamp: Date.now() // Ajouter un timestamp pour prévenir les replay attacks
  };

  // Crypter les données
  const encryptedData = encryptData(credentials);
  console.log(encryptedData);

    const { success ,error,timer } = await login(encryptedData);
    if (!success) {
      setIsError(error); // Handle login failure
      setIsTimer(timer);
      console.log(error);
  }
  }

  if (isAuthenticated) {
    return <Redirect to={'/home'} />;
  }

  return (
    <div className={styles.limiter}>
      <div className={styles.containerLogin100}>
        {isError && (
          <span style={{ color: "red" }}>
            {isError}
          </span>
        )}
        <div className={styles.wrapLogin100}>
          <form autoComplete="off" className={styles.loginForm}>
            <div className={styles.logo}>
              <img
                width={120}
                src={process.env.REACT_APP_LOGO}
                alt="Logo GENI6TM"
              />
            </div>
            <div className={styles.wrapInput100}>
              <input
                type="text"
                className={styles.inputW}
                placeholder="Username"
                autoComplete="off"
                value={username}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
              />
            </div>
            <div className={styles.wrapInput100}>
              <input
                type="password"
                className={styles.inputW}
                placeholder="Password"
                autoComplete="off"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>
            
            <p>
              <a href="/LostPassword" class="align-left">
                Mot de passe oublié?
              </a>
            </p>
            <div className={styles.containerFormLoginBtn}>
              <button onClick={postLogin} className={styles.login100Btn}>
                S'identifier
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className={styles.footer}>
        {/* <div>
          <img width="150" src="img/ITGB_PNG.png" alt="Logo GBit" />
          <p>
            Copyright © 2014-2024{" "}
            <a target="blanc" href="https://it-gb.com/">
              SARL ITGB
            </a>
          </p>
        </div> */}
      </div>
    </div>
  );
}

export default Login;
