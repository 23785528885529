// src/contexts/AuthContext.jsx
import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const checkAuthStatus = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(process.env.REACT_APP_CHECK_AUTH_URL, {
        withCredentials: true
      });
      setAuthToken(response.data.token);
      setUser(jwtDecode(response.data.token));
      setIsAuthenticated(true);
    } catch (error) {
      setUser(null);
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  };

  const validateToken = async () => {
    const res = await axios({
      url: process.env.REACT_APP_AUTHENTIFICATE,
      method: 'POST',
      headers: {
        Authorization: `Bearer ` + authToken,
      },
    });
    if (res.data.error) throw res.data.error;
    return res.data;
  }

  useEffect(() => {
    checkAuthStatus();
  }, []);

  useEffect(() => {
    if (authToken) {
      //validateToken();
      //scheduleTokenRefresh(authToken);
    };
  }, [authToken]);

  function toFormData(data) {
    const tmp = new FormData();
    Object.keys(data).forEach((key) => {
      tmp.append(key, data[key]);
    });
    return tmp;
  }

  const login = async (encryptData) => {
    try {
      setIsLoading(true);
      const response = await axios.post(process.env.REACT_APP_LOGIN_URL, toFormData({
        data: encryptData
      }), {
        withCredentials: true, headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }, body: JSON.stringify({ data: encryptData }),
      });
      if (response.data.error) return { success: false, error: response.data.error ,timer : response.data.time};

      setAuthToken(response.data.token);
      setUser(jwtDecode(response.data.token));
      setIsAuthenticated(true);
      return { success: true };
    } catch (error) {
      return { success: false, error: error.response.data.error };
    } finally {
      setIsLoading(false);
    }
  };

  // Vérifie et rafraîchit le token automatiquement
  const refreshToken = useCallback(async () => {
    try {
      const response = await fetch('/api/refresh', {
        method: 'POST',
        credentials: 'include', // Envoie le cookie HttpOnly
      });

      if (response.ok) {
        const data = await response.json();
        setAuthToken(data.authToken);
        scheduleTokenRefresh(data.authToken); // Replanifie le prochain rafraîchissement
      } else {
        logout(); // Déconnecte si le refresh échoue
      }
    } catch (error) {
      console.error('Erreur lors du rafraîchissement du token:', error);
      logout();
    }
  }, []);

  // Planifie le rafraîchissement avant l'expiration du token
  const scheduleTokenRefresh = (token) => {
    const payload = JSON.parse(atob(token.split('.')[1])); // Décoder le payload du JWT
    const expirationTime = payload.exp * 1000; // Convertir en millisecondes
    const currentTime = Date.now();
    const timeout = expirationTime - currentTime + 2000;//- 60000; // 1 minute avant expiration
    console.log(`${timeout} after expiration`);
    if (timeout > 0) {
      setTimeout(validateToken, timeout);
    }
  };

  const logout = async () => {
    try {
      setIsLoading(true);
      await axios.get(process.env.REACT_APP_LOGOUT_URL, {}, {
        //withCredentials: true
      })
    } finally {
      setIsAuthenticated(false);
      setIsLoading(false);
     // setAuthToken(null);
      setUser(null);
    }
  };

  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      isLoading,
      user,
      login,
      logout,
      checkAuthStatus
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);